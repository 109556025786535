<template>
  <a-modal :visible="visible" title="选择审批流程" @cancel="handleCloseModal" :width="378" :maskClosable="false">
    <a-select v-model="checkedTemplates" placeholder="请选择审批流程" mode="multiple" style="width: 338px;" :filter-option="filterOption" show-search>
      <a-select-option v-for="template in templates" :value="template.templateIdentity"
                       :key="template.templateIdentity">{{ template.approTemplateName }}
      </a-select-option>
    </a-select>
    <div class="footer">
      <a-button type="primary" @click="handleSave">保存</a-button>
      <a-button @click="handleCloseModal">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ProcessModal',
  props: {
    data: {
      type: Object,
      default: () => ({
        datasource: {
          ticket: {
            templates: []
          }
        }
      })
    },
    visible: Boolean,
    handleCloseModal: Function,
    handleTemplatesChange: Function
  },
  data() {
    return {
      templates: [],
      templatesObj: {},
      checkedTemplates: []
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSave() {
      this.handleTemplatesChange(this.checkedTemplates, this.templatesObj);
      this.handleCloseModal();
    },
    async getTicketTemplates() {
      const res = await this.$services.listApproTemplates({ data: { approvalType: this.data.datasource.ticket.platform } });
      if (res.success) {
        this.templates = res.data;
        const obj = {};
        res.data.forEach((t) => {
          obj[t.templateIdentity] = t;
        });
        this.templatesObj = obj;
      }
    }
  },
  created() {
    this.checkedTemplates = this.data.datasource.ticket.templates;
    this.getTicketTemplates();
  },
  watch: {
    'data.datasource': {
      handler(newValue) {
        console.log(newValue);
      },
      deep: true
    }
  }
};
</script>

<style scoped>

</style>
