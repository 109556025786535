<template>
  <a-modal :visible="visible" :mask-closable=false :width="480" title="修改数据库账号" @cancel="handleCloseModal">
    <div class="edit-account-modal">
      <a-form-model ref="accountForm" :label-col="{span: 4}" :model="currentDs"
                    :rules="accountRules" :wrapper-col="{span:17}">
        <a-form-model-item label="认证方式">
          <a-select v-model="currentDs.type" @change="handleChangeSecurityType"
                    style="width: 160px;">
            <a-select-option v-for="security in securityOptions" :key="security.securityType"
                             :rowKey="security.securityType">{{ security.secrityTypeI18nName || security.securityType }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="currentDs.type === 'USER_PASSWD'" label="账号" prop="userName">
          <a-input v-model="currentDs.userName" style="width: 350px;"/>
        </a-form-model-item>
        <a-form-model-item v-if="currentDs.type !== 'NONE'" label="密码" prop="password">
          <cc-password-input v-model="currentDs.password" style="width: 350px;"/>
        </a-form-model-item>
        <a-form-model-item label=" ">
          <a-button style="margin-right: 10px;" @click="_testConnection">测试连接</a-button>
          <cc-iconfont v-if="testConnectMsg" :color="testConnectMsg === '连接成功' ? 'green' : 'red'"
                       :name="testConnectMsg === '连接成功' ? 'success' : 'error'"/>
          {{ testConnectMsg }}
        </a-form-model-item>
      </a-form-model>
      <div class="footer">
        <a-button type="primary" @click="handleUpdateDsAccount">保存</a-button>
        <a-button @click="handleCloseModal">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import datasourceMixin from '@/mixins/datasourceMixin';

export default {
  name: 'ModifyDsAccountModal',
  props: {
    ds: Object,
    visible: Boolean,
    handleCloseModal: Function
  },
  mixins: [datasourceMixin],
  data() {
    return {
      testConnectMsg: '',
      currentDs: {},
      securityOptions: [],
      accountRules: {
        userName: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    _testConnection() {
      this.$refs.accountForm.validate(async (valid) => {
        if (valid) {
          const {
            bindClusterId,
            dataSourceType,
            userName,
            password,
            region,
            privateHost,
            publicHost,
            defaultHost,
            deployEnvType,
            type
          } = this.currentDs;
          const data = {
            bindClusterId,
            dataSourceType,
            region,
            privateHost,
            publicHost,
            defaultHost,
            deployEnvType,
            dsPropsJson: JSON.stringify({
              userName,
              password
            }),
            securityType: type
          };
          this.testConnection(data);
        } else {
          return false;
        }
      });
    },
    async handleUpdateDsAccount() {
      const {
        id,
        userName,
        password
      } = this.currentDs;
      const data = {
        dataSourceId: id,
        dsPropsJson: JSON.stringify({
          userName,
          password
        })
      };

      const res = await this.$services.updateDsAccount({
        data,
        msg: '更新账号成功'
      });

      if (res.success) {
        this.handleCloseModal();
      }
    },
    async getSecurityOption() {
      const {
        dataSourceType,
        deployEnvType
      } = this.ds;
      const res = await this.$services.listDsSecurityOption({
        data: {
          dataSourceType,
          deployEnvType
        }
      });

      if (res.success) {
        const obj = {};
        this.securityOptions = [...res.data.securityOptions];
        res.data.securityOptions.forEach((security) => {
          obj[security.securityType] = security;
        });
        this.securityOptionsObj = obj;
        this.currentDs.type = this.securityOptions.length > 0 ? this.securityOptions[0].securityType : '';
      }
    },
    handleChangeSecurityType() {
      this.currentDs = { ...this.currentDs };
    }
  },
  created() {
    this.currentDs = this.ds;
    this.getSecurityOption();
  }
};
</script>

<style scoped>

</style>
