<template>
  <div class="step step-three">
    <a-table :columns="datasourceListColumns" :data-source="datasourceList" :rowKey="record=>record.instanceId"
             size="small">
      <template slot="white-title">
        添加白名单
        <a-popover>
          <template slot="content">
            <p>绑定的集群机器的ip会自动被添加到对应数据库实例的白名单列表中</p>
          </template>
          <a-icon theme='filled' type="question-circle"/>
        </a-popover>
      </template>
      <template slot="name" slot-scope="record">
        {{ record.instanceId }} / {{ record.instanceDesc }}
      </template>
      <template slot="host" slot-scope="record">
        <div v-if="record.privateHost">
          内网: {{ record.privateHost.connectionString }}:{{ record.privateHost.port }}
        </div>
        <div v-if="record.publicHost">
          外网: {{ record.publicHost.connectionString }}:{{ record.publicHost.port }}
        </div>
      </template>
      <template slot="defaultHost" slot-scope="record">
        {{ '' }}
        <div v-if="record.publicHost && !record.privateHost">外网</div>
        <div v-if="!record.publicHost && record.privateHost">内网</div>
        <a-select v-if="record.privateHost && record.publicHost" :default-value="record.defaultHost"
                  size="small" @change="handleDefaultHostChange(record, $event)">
          <a-select-option rowKey="out" value="外网">外网</a-select-option>
          <a-select-option rowKey="in" value="内网">内网</a-select-option>
        </a-select>
      </template>
      <template slot="white" slot-scope="record">
        <a-checkbox @change="handleAddWhiteIp(record, $event)"/>
      </template>
      <template slot="whiteType" slot-scope="record">
        <a-select v-if="record.whiteList.addIpWhiteList" :default-value="record.whiteList.type"
                  size="small" style="width: 100px;" @change="handleChangeWhiteType(record, $event)">
          <a-select-option v-for="type in ipTypeOptions" :key="type" :rowKey="type" :value="type">
            {{ type }}
          </a-select-option>
        </a-select>
      </template>
    </a-table>
    <div class="footer">
      <a-button @click="handleBack">上一步</a-button>
      <a-button type="primary" @click="handleSubmitData(2, datasourceList)">下一步：数据库账号</a-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Three',
  props: {
    stepData: Array,
    handleBack: Function,
    handleSubmitData: Function
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          Vue.set(this.selectedDatasource.whiteList, 'children', selectedRows);
          let num = 0;
          selectedRows.forEach((row) => {
            num += row.workerCount;
          });
          Vue.set(this.selectedDatasource.whiteList, 'num', num);
        }
      };
    }
  },
  data() {
    return {
      selectedDatasource: {},
      selectedCluster: {},
      ipTypeOptions: ['全部', '内网IP', '公网出口IP'],
      stepThreeData: {},
      datasourceList: [],
      datasourceListColumns: [
        {
          title: '实例名称/ID',
          scopedSlots: { customRender: 'name' }
        }, {
          title: 'Host',
          scopedSlots: { customRender: 'host' }
        }, {
          title: '默认访问地址',
          scopedSlots: { customRender: 'defaultHost' },
          width: 150
        }, {
          slots: { title: 'white-title' },
          scopedSlots: { customRender: 'white' },
          width: 120
        }, {
          title: '白名单类型',
          scopedSlots: { customRender: 'whiteType' },
          width: 150
        }
      ],
      clusterList: [],
      clusterListColumns: [
        {
          title: '集群名称/ID',
          width: 400,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '可用数量/机器数量',
          width: 200,
          scopedSlots: { customRender: 'num' }
        }
      ],
      innerColumns: [
        {
          title: 'ip',
          scopedSlots: { customRender: 'ip' }
        }
      ],
      ipMap: {}
    };
  },
  methods: {
    handleChangeWhiteType(record, value) {
      this.datasourceList.forEach((ds) => {
        if (ds.id === record.id) {
          ds.whiteList.type = value;
        }
      });
      this.datasourceList = [...this.datasourceList];
    },
    handleAddWhiteIp(record, e) {
      this.datasourceList.forEach((ds) => {
        if (ds.instanceId === record.instanceId) {
          ds.whiteList.addIpWhiteList = e.target.checked;
        }
      });
      this.datasourceList = [...this.datasourceList];
    },
    async expand(expanded, record) {
      if (expanded) {
        const res = await this.$services.getWorkerList({ data: { clusterId: record.id } });
        if (res.success) {
          this.selectedCluster = record;
          Vue.set(this.ipMap, record.id, res.data);
        }
      }
    },
    handleIpTypeChange(e) {
      Vue.set(this.selectedDatasource.whiteList, 'type', e.target.value);
    },
    async handleVisibilityChange(ds, visible) {
      if (visible) {
        this.selectedDatasource = ds;
        const res = await this.$services.getClusterList({ data: {} });
        if (res.success) {
          this.clusterList = res.data;
        }
      } else {
        const { datasourceList } = this;
        datasourceList.forEach((ds1) => {
          if (ds1.instanceId === this.selectedDatasource.instanceId) {
            ds1 = this.selectedDatasource;
          }
        });
        this.datasourceList = datasourceList;
        this.$forceUpdate();
      }
    },
    handleDefaultHostChange(record, value) {
      const { datasourceList } = this;
      datasourceList.forEach((ds) => {
        if (ds.instanceId === record.instanceId) {
          ds.defaultHost = value;
        }
      });
      this.datasourceList = datasourceList;
    }
  },
  created() {
    const datasourceList = [];
    this.stepData[1].selectedDatasourceList.forEach((ds) => {
      ds.defaultHost = ds.publicHost ? '外网' : '内网';
      ds.whiteList = {
        type: '全部',
        num: 0,
        children: [],
        addIpWhiteList: false
      };
      datasourceList.push(ds);
    });
    this.datasourceList = datasourceList;
  }
};
</script>

<style lang="less" scoped>

</style>
