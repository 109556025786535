<template>
  <div>
    <query-header :handle-query="handleQuery" :handle-refresh="getDsParams" :showSelect="false"
                  :showAddBtn="false" :query="queryForm" placeholder="请输入参数名称查询">
      <a-button type="primary" @click="handleShowConfirmEditParams(true)">生效配置</a-button>
    </query-header>
    <a-table :columns="configColumns" :data-source="configData" :locale="{emptyText: '暂无数据'}"
             :rowKey="record => record.id"
             size="small">
      <template slot="configName" slot-scope="record">
        <div class="config-name">
          {{record.configName}}
          <a-tooltip placement="right">
            <template slot="title">
              <span>{{record.description}}</span>
            </template>
            <a class="config-desc-tip">
              <a-icon type="info-circle" />
            </a>
          </a-tooltip>
        </div>
      </template>
      <template slot="defaultValue" slot-scope="record">
        {{record.defaultValue||'-'}}
      </template>
      <template slot="configValue" slot-scope="record">
        {{record.configValue||'-'}}
      </template>
      <template slot="editParam" slot-scope="record">
        <div>
          <div v-if="!record.readOnly">
                      <span
                        style="margin-right: 16px;display: inline-block;width: 60px;">{{
                          record.currentCount
                        }}</span><span>
                                    <a-popover v-model="record.visible" transfer trigger="click"
                                            @on-popper-show="handlePopShow(record)"
                                            placement="right" width="250">
                                   <a-icon style="cursor: pointer" type="edit" />
                                <div slot="content">
                                    <p style="font-size: 12px;margin-bottom: 10px">修改参数为:</p>
                                    <p style="margin-bottom: 10px"><a-input size="small"
                                                                          style="width: 200px"
                                                                          v-model="currentValue"/></p>
                                    <p><a-button style="margin-right: 5px" type="primary"
                                                  size="small"
                                                  @click="handleEditCurrent(configData,record)">确定</a-button></p>
                                </div>
                            </a-popover>
                                <a-tooltip transfer style="margin-left: 5px"
                                         placement="right"
                                         v-if="record.currentCount">
                                  <template slot="title">
                                      <span>撤销</span>
                                  </template>
                                  <a style="font-size: 16px;"
                                     @click="handleCancelEdit(configData,record)"><Icon
                                  type="md-undo"/></a></a-tooltip></span>
          </div>
          <div v-if="record.readOnly">
            只读参数
          </div>
        </div>
      </template>
      <template slot="configGroup" slot-scope="record">
        <div>
          <span class="config-label" :style="`color:${colors[record.configGroup || record.userConfigTagType]};border-color:${colors[record.configGroup||record.userConfigTagType]}`">
            {{record.configGroup || record.userConfigTagType}}
          </span>
        </div>
      </template>
      <template #valueAdvance="record">
        {{record.valueAdvance || record.valueRange}}
      </template>
    </a-table>
    <a-modal
      v-model="showParamsEditConfirm"
      title="确定要生效以下配置吗"
      footer-hide
      width="800px"
    >
      <div style="max-height: 500px;overflow: auto">
        <div id="elem"></div>
        <Table border size="small" :columns="editColumns" :data="editedParams"></Table>
        <div class="footer" style="margin-top: 20px">
          <a-button type="primary" @click="handleConfirmEditParams">确认</a-button>
          <a-button @click="handleCancelEditParams">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import QueryHeader from '../components/QueryHeader.vue';
import { PARAMS_CONFIG } from '../../../consts';

export default {
  components: {
    QueryHeader
  },
  mounted() {
    this.dataSourceId = this.$route.params.id;
    if (this.$route.name === 'User_Config') {
      this.type = 'user';
    }
    console.log(this.$route);
    this.getDsParams();
  },
  data() {
    return {
      type: 'ds',
      dataSourceId: 0,
      queryForm: {
        param: '',
        text: ''
      },
      configColumns: [
        {
          title: '参数名称',
          key: 'configName',
          scopedSlots: { customRender: 'configName' },
          width: 220
        },
        {
          title: '参数默认值',
          key: 'defaultValue',
          scopedSlots: { customRender: 'defaultValue' }
        },
        {
          title: '当前运行值',
          key: 'configValue',
          scopedSlots: { customRender: 'configValue' }
        },
        {
          title: '修改后的参数值',
          key: 'editParam',
          scopedSlots: { customRender: 'editParam' }
        },
        {
          title: '可修改范围',
          key: 'valueAdvance',
          scopedSlots: { customRender: 'valueAdvance' }
        },
        {
          title: '标签',
          key: 'configGroup',
          scopedSlots: { customRender: 'configGroup' }
        }
      ],
      trueData: [],
      configData: [],
      currentValue: '',
      editedParams: [],
      showParamsEditConfirm: false,
      editColumns: [
        {
          title: '参数名',
          dataIndex: 'configName',
          key: 'configName',
          width: 300
        },
        {
          title: '当前运行值',
          dataIndex: 'configValue',
          key: 'configValue'
        },
        {
          title: '修改后的值',
          dataIndex: 'currentCount',
          key: 'currentCount'
        }
      ],
      paramsDataMap: {},
      colors: {
        OPTIONS: '#56bc76',
        GENERAL: '#a88108',
        COMMON: '#a88108',
        CLOUD: '#32adfa'
      }
    };
  },
  methods: {
    handleQuery() {
      this.configData = [];
      this.trueData.forEach((data) => {
        if (data.configName.indexOf(this.queryForm.text) > -1) {
          this.configData.push(data);
        }
      });
    },
    async getDsParams() {
      const res = await this.$services[PARAMS_CONFIG[this.type].get]({ data: { dataSourceId: this.dataSourceId } });
      if (res.success) {
        this.queryForm.text = '';
        this.configData = res.data;
        this.trueData = res.data;
      }
    },
    handleCancelEdit(data, row) {
      data.map((item) => {
        if (item.configName === row.configName) {
          item.currentCount = '';
        }
        return null;
      });
      this.configData = Object.assign([], data);
    },
    handleEditCurrent(data, row) {
      data.map((item) => {
        if (item.configName === row.configName) {
          item.currentCount = this.currentValue;
        }
        return null;
      });
      this.configData = Object.assign([], data);
      // row.currentCount = this.currentValue;
      this.currentValue = '';
      row.visible = false;
    },
    handlePopShow(row) {
      this.currentValue = row.count;
    },
    handleShowConfirmEditParams() {
      this.editedParams = [];
      this.showParamsEditConfirm = true;
      Object.values(this.configData).map((item) => {
        if ((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue) {
          this.editedParams.push(item);
        }
        return null;
      });
    },
    handleConfirmEditParams() {
      this.showParamsEditConfirm = false;
      if (this.editedParams.length === 0) {
        this.$Modal.warning({
          title: '操作异常',
          content: '请修改需要生效的参数。'
        });
        return;
      }
      const data = {};
      this.editedParams.forEach((item) => {
        data[item.configName] = item.currentCount;
      });
      this.$services[PARAMS_CONFIG[this.type].edit]({
        data: this.type === 'user' ? {
          updateConfigs: data
        } : {
          dataSourceId: this.dataSourceId,
          configMap: data
        }
      })
        .then((res) => {
          if (res.code === '1') {
            this.paramsDataMap = {};
            this.getDsParams();
            this.editedParams = [];
            this.$Message.success('配置已修改成功!');
          }
        });
    },
    handleCancelEditParams() {
      this.editedParams = [];
      this.showParamsEditConfirm = false;
      this.showParamsEdit = false;
    }
  }
};
</script>
<style lang="less">
.config-name {
  position: relative;
  .config-desc-tip {
    position: absolute;
    right: 20px;
    top: 0;
  }
}
.config-label{
  display: inline-block;
  padding: 2px 6px;
  border: 1px solid;
}
</style>
