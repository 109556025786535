<template>
  <div class="step step-self">
    <a-form-model v-if="step==='ds'" ref="selfFormRef" :model="form" :rules="formValidate">
      <a-form-model-item :prop="hosts[0].type" label="网络地址">
        <div style="display: flex;">
          <a-input v-model.trim="hosts[0].host" style="width: 462px;" placeholder="IP">
            <a-select slot="addonBefore" v-model="hosts[0].type" style="width: 100px;"
                      @change="handleChangeHostType(1)">
              <a-select-option value="privateHost">内网</a-select-option>
              <a-select-option value="publicHost">外网</a-select-option>
            </a-select>
          </a-input>
          <a-input v-model.trim="hosts[0].port" style="width: 80px;margin-top: -1px;border-left: none;" placeholder="PORT"/>
          <a-checkbox v-model="hosts[0].default" style="margin: 0 12px;"
                      @change="handleChangeDefaultHost(1, $event)">默认访问地址
          </a-checkbox>
          <div v-show="!hosts[1].show" style="cursor: pointer" @click="handleShowSecondHost">
            <cc-iconfont :size="20" color="#51D300" name="add"/>
          </div>
          <div v-show="hosts[1].show" @click="handleDeleteHost(0)">
            <cc-iconfont :size="20" color="red" name="delete"/>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item v-if="hosts[1].show" label=" ">
        <div v-if="hosts[1].show" style="display: flex;">
          <a-input v-model.trim="hosts[1].host" style="width: 462px;" placeholder="IP">
            <a-select slot="addonBefore" v-model="hosts[1].type" style="width: 100px;"
                      @change="handleChangeHostType(0)">
              <a-select-option value="privateHost">内网</a-select-option>
              <a-select-option value="publicHost">外网</a-select-option>
            </a-select>
          </a-input>
          <a-input v-model.trim="hosts[1].port" style="width: 80px;margin-top: -1px;border-left: none;" placeholder="PORT"/>
          <a-checkbox v-model="hosts[1].default" style="margin: 0 12px;"
                      @change="handleChangeDefaultHost(0, $event)">默认访问地址
          </a-checkbox>
          <div v-show="hosts[1].show" @click="handleDeleteHost(0)">
            <cc-iconfont :size="20" color="red" name="delete"/>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="认证方式">
        <a-select v-model="form.type"
                  style="width: 160px;">
          <a-select-option v-for="security in securityOptions" :key="security.securityType"
                           :rowKey="security.securityType">{{ security.secrityTypeI18nName || security.securityType }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.type!==SECURITY_TYPE.ONLY_PASSWD&&form.type!==SECURITY_TYPE.NONE" label="数据库账号" prop="userName">
        <a-input v-model.trim="form.userName" autocomplete="new-password" style="width: 462px;"/>
      </a-form-model-item>
      <a-form-model-item v-if="form.type!==SECURITY_TYPE.NONE" label="数据库密码" prop="password">
        <cc-password-input v-model="form.password" style="width: 462px;"/>
      </a-form-model-item>
      <a-form-model-item label="数据源名称" prop="instanceDesc">
        <a-input v-model="form.instanceDesc" style="width: 462px"/>
        <div class="name-tip">名称便于记忆，方便使用时识别，如交易库、用户库、测试库等。</div>
      </a-form-model-item>
      <a-form-model-item label="物理位置" prop="region">
        <cc-region-select v-model="form.region" :env="stepData[0].deployEnvType"/>
      </a-form-model-item>
      <a-form-model-item label="工单">
        <a-switch v-model="form.ticket" @change="handleTicketChange"/>
        <div v-if="form.ticket">
          <div class="ticket">
            审批方式
            <a-radio-group v-model="form.ticketType">
              <a-radio value="DINGDING">
                钉钉
              </a-radio>
            </a-radio-group>
            <a-select v-model="form.ticketArr" placeholder="请选择审批流程" mode="multiple" :filter-option="filterOption" show-search>
              <a-select-option v-for="template in ticketTemplates" :value="template.templateIdentity"
                               :key="template.templateIdentity">{{ template.approTemplateName }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label=" ">
        <div class="test-connection">
          <a-button @click="_testConnection()" style="width: 120px">测试连接</a-button>
          <div class="error-msg">
            <cc-iconfont v-if="testConnectMsg" :color="testConnectMsg === '连接成功' ? '#52C41A' : '#FF1815'"
                         :name="testConnectMsg === '连接成功' ? 'success' : 'error'"/>
            {{ testConnectMsg }} <a-button type="link" v-if="tested && !(testConnectMsg === '连接成功')" @click="showErrorMsgModal=true">详情</a-button>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label=" ">
        <a-button style="width: 120px;margin-right: 16px;" @click="handleBack(1, form)">上一步</a-button>
        <a-button style="width: 120px;" type="primary" @click="handleAddDs">确认添加</a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal :visible="showErrorMsgModal" title="连接失败" :mask-closable="false" @cancel="showErrorMsgModal=false">
      <div>
        {{testConnectMsgDetail}}
      </div>
      <div class="footer">
        <a-button @click="showErrorMsgModal=false">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import datasourceMixin from '@/mixins/datasourceMixin';
import { SECURITY_TYPE } from '@/consts';

export default {
  name: 'Self',
  props: {
    stepData: Array,
    handleBack: Function
  },
  mixins: [datasourceMixin],
  data() {
    const validatePrivateHost = (rule, value, callback) => {
      this.hosts.forEach((host) => {
        if ((host.type === 'privateHost' && !host.host) && (host.type === 'publicHost' && (!host.host || !host.port))) {
          return callback(new Error('内网不能为空'));
        }
      });
      callback();
    };
    const validatePublicHost = (rule, value, callback) => {
      this.hosts.forEach((host) => {
        if ((host.type === 'publicHost' && !host.host) && (host.type === 'privateHost' && (!host.host || !host.port))) {
          return callback(new Error('外网不能为空'));
        }
      });
      callback();
    };
    return {
      SECURITY_TYPE,
      showErrorMsgModal: false,
      step: 'ds',
      tested: false,
      hosts: [
        {
          type: 'privateHost',
          default: true,
          host: '',
          port: '',
          show: true
        },
        {
          type: 'publicHost',
          default: false,
          host: '',
          port: '',
          show: false
        }
      ],
      form: {
        userName: '',
        password: '',
        instanceDesc: '',
        region: 'customer',
        order: false,
        ticket: false,
        ticketType: 'DINGDING',
        ticketArr: [],
        type: ''
      },
      securityOptions: [],
      formValidate: {
        privateHost: [{
          validator: validatePrivateHost,
          trigger: 'blur'
        }],
        publicHost: [{
          validator: validatePublicHost,
          trigger: 'blur'
        }],
        userName: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }
        ],
        region: [
          {
            required: true,
            message: '地区不能为空'
          }
        ]
      },
      orderForm: {
        platform: '',
        type: [],
        process: ''
      },
      orderFormValidate: {},
      ticketTemplates: [],
      ticketTemplatesObj: {}
    };
  },
  created() {
    this.form = { ...this.form, ...this.stepData[1] };
    this.getSecurityOption();
  },
  methods: {
    async getSecurityOption() {
      const {
        dataSourceType,
        deployEnvType
      } = this.stepData[0];
      const res = await this.$services.listDsSecurityOption({
        data: {
          dataSourceType,
          deployEnvType
        }
      });

      if (res.success) {
        const obj = {};
        this.securityOptions = [...res.data.securityOptions];
        res.data.securityOptions.forEach((security) => {
          obj[security.securityType] = security;
        });
        this.securityOptionsObj = obj;
        this.form.type = this.securityOptions.length > 0 ? this.securityOptions[0].securityType : '';
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleTicketChange(checked) {
      if (checked) {
        const res = await this.$services.listApproTemplates({ data: { approvalType: 'DINGDING' } });

        if (res.success) {
          this.ticketTemplates = res.data;
          const ticketTemplatesObj = {};
          res.data.forEach((ticket) => {
            ticketTemplatesObj[ticket.templateIdentity] = ticket;
          });
          this.ticketTemplatesObj = ticketTemplatesObj;
        } else {
          Vue.set(this.form, 'ticket', false);
        }
      }
    },
    handleDeleteHost(index) {
      if (index === 0) {
        const shiftHost = this.hosts.shift();
        this.hosts.push(shiftHost);
      }

      this.hosts[0].default = true;
      this.hosts[1] = {
        ...this.hosts[1],
        host: '',
        port: '',
        default: false,
        show: false
      };
    },
    handleShowSecondHost() {
      this.hosts.forEach((host) => {
        host.show = true;
      });

      this.hosts = [...this.hosts];
    },
    handleChangeHostType(index) {
      const compareIndex = 1 ^ index;
      this.hosts[index].type = this.hosts[compareIndex].type === 'privateHost' ? 'publicHost' : 'privateHost';
      this.hosts = [...this.hosts];
    },
    _testConnection() {
      this.$refs.selfFormRef.validate((valid) => {
        if (valid) {
          this.tested = true;
          this.testConnection(this.generateData());
        }
      });
    },
    generateData() {
      const {
        bindClusterId,
        dataSourceType,
        deployEnvType,
        envId
      } = this.stepData[0];
      const {
        userName,
        password,
        instanceDesc,
        region,
        type
      } = this.form;

      let privateHost = '';
      let publicHost = '';
      let defaultHost = '';
      this.hosts.forEach((host) => {
        if (host.type === 'privateHost') {
          privateHost = `${host.host}:${host.port}`;
        } else {
          publicHost = `${host.host}:${host.port}`;
        }

        if (host.default) {
          defaultHost = `${host.host}:${host.port}`;
        }
      });
      const templates = [];
      this.form.ticketArr.forEach((t) => {
        templates.push({
          approvalType: this.form.ticketType,
          templateIdentity: t,
          templateName: this.ticketTemplatesObj[t].approTemplateName
        });
      });
      const dsApproTemplatesJson = JSON.stringify(templates);
      const data = {
        defaultHost,
        bindClusterId,
        dataSourceType,
        deployEnvType,
        dsPropsJson: JSON.stringify({
          userName,
          password
        }),
        privateHost,
        publicHost,
        region,
        instanceDesc,
        securityType: type,
        envId,
        dsApproTemplatesJson
      };

      return data;
    },
    async handleAddDs() {
      if (this.testConnectMsg === '连接成功') {
        const data = new FormData();
        data.append('addDsFOJson', JSON.stringify(this.generateData()));
        const res = await this.$services.addDs({
          data,
          headers: {
            'content-type': 'multipart/form-data'
          },
          msg: '添加数据库成功'
        });

        if (res.success) {
          await this.$router.push({ name: 'System_DataSource' });
        }
      }
    },
    handleChangeDefaultHost(index, e) {
      this.hosts[index].default = !e.target.checked;
      this.hosts = [...this.hosts];
    }
  },
  watch: {
    firstSelectedNetworkType(newValue, oldValue) {
      Vue.set(this.form, oldValue, '');
    }
  }
};
</script>

<style lang="less">
.step-self {
  .ticket {
    width: 462px;
    padding: 2px 12px 12px;
    background-color: #F4F4F4;
  }

  .ant-input-group-addon {
    border-radius: 0;
  }

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-label {
    width: 80px;
  }

  margin-top: 20px;

  .name-tip {
    color: #888;
  }

  .datasource {
    text-align: left;

    .test-connection {
      display: flex;
      align-items: center;

      .error-msg {
        margin-left: 14px;
        height: 32px;
        line-height: 32px;
      }
    }

    & > div > div:first-child {
      margin-bottom: 20px;
    }
  }
}
</style>
