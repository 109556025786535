<template>
  <div class="step step-five">
    <a-table size="small" :columns="datasourceListColumns" :data-source="datasourceList"
             :rowKey="record=>record.instanceId">
<!--      <template slot="ticket-title">-->
<!--        <a-checkbox :indeterminate="selectedDatasourceNum > 0 && selectedDatasourceNum < datasourceList.length"-->
<!--                  :checked="selectedDatasourceNum === datasourceList.length"-->
<!--                  @change="handleAllChange"/>-->
<!--        开启审批流程-->
<!--        <a-popover>-->
<!--          <cc-iconfont name="tip-black" size="14"/>-->
<!--          <template slot="content">-->
<!--            绑定的集群机器的ip会自动被添加到对应数据库实例的白名单列表中-->
<!--          </template>-->
<!--        </a-popover>-->
<!--      </template>-->
<!--      <template slot="ticket" slot-scope="record">-->
<!--        <a-checkbox v-model="record.ticket.turn" @change="handleChange"/>-->
<!--      </template>-->
      <template slot="name" slot-scope="record">
        {{ record.instanceDesc }}/{{ record.instanceId }}
      </template>
      <template slot="platform" slot-scope="record">
        <a-select v-model="record.ticket.platform" :filter-option="filterOption" show-search>
          <a-select-option v-for="platform in platforms" :value="platform.value" :key="platform.value">
            {{ platform.label }}
          </a-select-option>
        </a-select>
      </template>
      <template slot="process" slot-scope="record">
        {{record.ticket.templates.length}}个
        <a-button type="link" @click="handleSelectProcess(record)" >选择审批流程</a-button>
      </template>
    </a-table>
    <div class="footer">
      <a-button @click="handleBack">上一步</a-button>
      <a-button type="primary" @click="handleSubmit">下一步: 确认添加</a-button>
    </div>
    <process-modal :visible="showProcessModal" :handle-close-modal="handleCloseModal"
                   :data="{datasource: selectedDatasource}" v-if="showProcessModal"
                   :handle-templates-change="handleTemplatesChange"/>
  </div>
</template>

<script>
import ProcessModal from '@views/system/datasource/components/ProcessModal.vue';
import { WHITE_LIST_ADD_TYPE } from '@/consts';

export default {
  name: 'Five',
  components: { ProcessModal },
  props: {
    stepData: Array,
    handleBack: Function,
    handleSubmitData: Function
  },
  data() {
    return {
      selectedDatasourceNum: 0,
      selectedDatasource: {},
      datasourceList: [],
      datasourceListColumns: [
        {
          title: '实例名称/ID',
          scopedSlots: { customRender: 'name' }
        },
        // {
        //   slots: { title: 'ticket-title' },
        //   scopedSlots: { customRender: 'ticket' }
        // },
        {
          title: '审批平台',
          scopedSlots: { customRender: 'platform' }
        },
        {
          title: '审批流程',
          scopedSlots: { customRender: 'process' }
        }
      ],
      platforms: [{
        value: 'DINGDING',
        label: '钉钉'
      }],
      showProcessModal: false,
      templatesObj: {}
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSelectProcess(record) {
      this.showProcessModal = true;
      this.selectedDatasource = record;
    },
    handleCloseModal() {
      this.showProcessModal = false;
    },
    async addDs(ds) {
      const {
        deployEnvType,
        dataSourceType,
        bindClusterId,
        envId
      } = this.stepData[0];
      const {
        region,
        instanceId,
        instanceDesc,
        auth,
        whiteList,
        ticket
      } = ds;
      const privateHost = ds.privateHost ? `${ds.privateHost.connectionString}:${ds.privateHost.port}` : '';
      const publicHost = ds.publicHost ? `${ds.publicHost.connectionString}:${ds.publicHost.port}` : '';
      console.log(ds.defaultHost);
      const defaultHost = ds.defaultHost === '内网' ? privateHost : publicHost;
      const addIpWhiteList = whiteList.addIpWhiteList;

      const {
        platform,
        templates
      } = ticket;

      const templateArr = [];
      templates.forEach((t) => {
        templateArr.push({
          approvalType: platform,
          templateIdentity: t,
          templateName: this.templatesObj[t].approTemplateName
        });
      });
      const dsApproTemplatesJson = JSON.stringify(templateArr);
      const {
        userName,
        password
      } = auth.data;
      const addDsFOJson = {
        deployEnvType,
        dataSourceType,
        bindClusterId,
        envId,
        region,
        instanceId,
        instanceDesc,
        privateHost,
        publicHost,
        defaultHost,
        securityType: auth.type,
        autoCreateAccount: auth.auto,
        addIpWhiteList,
        dsPropsJson: JSON.stringify({
          userName,
          password
        }),
        dsApproTemplatesJson
      };

      if (addIpWhiteList) {
        addDsFOJson.whiteListAddType = WHITE_LIST_ADD_TYPE[whiteList.type];
      }

      const data = new FormData();
      data.append('addDsFOJson', JSON.stringify(addDsFOJson));

      if (auth.type === 'USER_PASSWD_WITH_TLS' && auth.data.tls) {
        data.append('securityFile', auth.data.tls, auth.data.tls.name);
      }

      if (auth.type === 'KERBEROS' && auth.data.kerberos && auth.data.keybat) {
        data.append('securityFile', auth.data.kerberos, auth.data.kerberos.name);
        data.append('secretFile', auth.data.keybat, auth.data.keybat.name);
      }

      const res = await this.$services.addDs({
        data,
        headers: {
          'content-type': 'multipart/form-data'
        },
        msg: '添加数据库成功'
      });

      return res;
    },
    async handleAddAllDs() {
      const dsArr = [];
      this.datasourceList.forEach((ds) => {
        dsArr.push(this.addDs(ds));
      });

      const res = await Promise.all(dsArr);
      let canNext = true;
      res.forEach((r) => {
        if (!r.success) {
          canNext = false;
        }
      });

      if (canNext) {
        await this.$router.push({ name: 'System_DataSource' });
      }
    },
    async handleSubmit() {
      await this.handleAddAllDs();
    },
    handleAllChange(value) {
      this.datasourceList.forEach((ds) => {
        ds.ticket.turn = value;
      });
      this.datasourceList = [...this.datasourceList];

      if (value) {
        this.selectedDatasourceNum = this.datasourceList.length;
      } else {
        this.selectedDatasourceNum = 0;
      }
    },
    handleChange(value) {
      let num = this.selectedDatasourceNum;
      if (value) {
        num++;
      } else {
        num--;
      }
      this.selectedDatasourceNum = num;
    },
    handleTemplatesChange(templates, templatesObj) {
      this.selectedDatasource.ticket.templates = templates;
      this.datasourceList.forEach((ds) => {
        if (ds.id === this.selectedDatasource.id) {
          ds = this.selectedDatasource;
        }
      });
      this.datasourceList = [...this.datasourceList];
      this.templatesObj = templatesObj;
    }
  },
  created() {
    this.datasourceList = this.stepData[3];
  }
};
</script>

<style scoped>

</style>
