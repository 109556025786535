const datasourceMixin = {
  data() {
    return {
      testConnectMsg: '',
      testConnectMsgDetail: ''
    };
  },
  methods: {
    async testConnection(data) {
      const res = await this.$services.connectDs({ data });
      this.testConnectMsg = res.success ? '连接成功' : '连接失败，请检查数据源登入信息！';
      if (res.fail) {
        this.testConnectMsgDetail = res.msg;
      }
    }
  }
};

export default datasourceMixin;
