<template>
  <div class="step step-one">
    <a-form-model ref="stepOneRef" :model="stepOneData"
                  :rules="stepOneDataValidate">
      <a-form-model-item label="部署类型" prop="deployEnvType">
        <a-radio-group v-model="stepOneData.deployEnvType" button-style="solid">
          <a-radio-button v-for="type in Object.values(deployEnvListMap)" :key="type.value" :value="type.value">
            {{ type.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="数据库类型" prop="dataSourceType">
        <a-radio-group v-model="stepOneData.dataSourceType" button-style="solid">
          <a-radio-button v-for="type in dsTypeList" :key="type.name" :value="type.type" style="width: 160px">
            <cc-data-source-icon style="background: transparent;width: 20px;height: 20px;line-height: 20px;"
                                 :instanceType="stepOneData.deployEnvType" :size="18" :type="type.type"
                                 color="#0087c7"/>
            <span>{{ type.name }}</span>
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="绑定集群" prop="bindClusterId">
        <div style="display: flex">
          <a-select v-model="stepOneData.bindClusterId" :dropdownMatchSelectWidth="false" placeholder="请选择"
                    style="width: 240px;" @change="handleClusterSelect">
            <a-select-option v-for="cluster in Object.values(clusterListMap)" :key="cluster.value"
                             :disabled="!cluster.runningCount" :value="cluster.value">
              {{ cluster.name }}/{{ cluster.desc }} 数量:{{ cluster.runningCount }}/{{ cluster.workerCount }}
            </a-select-option>
          </a-select>
          <div v-if="allClusterWorkers === 0 || allClusterRunningWorkers === 0"
               class="cluster-error">
            <cc-iconfont name="warning" size="16" color="#FFA30E" style="margin: 0 10px;"/>
            <a-button type="link" @click="handleGoAddMachine" style="padding: 0">
              {{ `暂无${allClusterRunningWorkers === 0 ? '存活' : ''}机器，请先至机器管理界面添加机器!` }}
            </a-button>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="运行环境" prop="envId">
        <a-select v-model="stepOneData.envId" placeholder="请选择" style="width: 240px;" @change="handleEnvChange">
          <a-select-option v-for="env in Object.values(envListMap)" :key="env.id" :value="env.id">
            {{ env.envName }}
          </a-select-option>
        </a-select>
        <a-button v-if="Object.values(envListMap).length === 0" style="margin-left: 10px;" @click="goEnvPage">添加环境
        </a-button>
      </a-form-model-item>
      <a-form-model-item label=" ">
        <a-button type="primary" @click="handleNext">下一步：选择数据源</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { CLUSTER_ENV } from '@/consts';
import { mapState } from 'vuex';
import { ACTIONS_TYPE } from '@/store/actions';
import Vue from 'vue';

export default {
  name: 'One',
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    stepOneDataProps: {
      type: Object
    },
    handleSubmitData: {
      type: Function,
      default: () => {
      }
    },
    handleUpdateDs: {
      type: Function,
      default: () => {
      }
    },
    closeModal: {
      type: Function,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState({
      deployEnvListMap: (state) => state.deployEnvListMap,
      clusterListMap: (state) => state.clusterListMap,
      dsTypeList: (state) => state.dsTypeList,
      allClusterWorkers: (state) => state.allClusterWorkers,
      allClusterRunningWorkers: (state) => state.allClusterRunningWorkers
    })
  },
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 14 },
      envListMap: {},
      stepOneData: {
        deployEnvType: CLUSTER_ENV.SELF_MAINTENANCE,
        clusterEnvName: '自建',
        dataSourceType: 'MySQL',
        bindClusterId: undefined,
        bindClusterName: '',
        envId: undefined,
        envName: ''
      },
      stepOneDataValidate: {
        deployEnvType: [
          {
            required: true,
            message: '部署类型不能为空'
          }
        ],
        dataSourceType: [
          {
            required: true,
            message: '数据库类型不能为空'
          }
        ],
        bindClusterId: [
          {
            required: true,
            message: '绑定集群不能为空'
          }
        ],
        envId: [
          {
            required: true,
            message: '运行环境不能为空'
          }
        ]
      },
      selectedAccessMachine: {},
      selectedEnv: {}
    };
  },
  methods: {
    handleClusterSelect(value) {
      this.stepOneData.bindClusterName = this.clusterListMap[value].clusterName;
    },
    goEnvPage() {
      this.$router.push({ name: 'System_Env' });
    },
    handleGoAddMachine() {
      this.$router.push({
        name: 'System_Machine'
      });
      window.$bus.emit('changeSidebar', '/system/machine');
    },
    handleEnvChange(envId) {
      Vue.set(this.stepOneData, 'envName', this.envListMap[envId].envName);
    },
    async listEnv() {
      const res = await this.$services.listDsEnv({ data: { envName: '' } });
      if (res.success) {
        const temp = {};
        res.data.forEach((env) => {
          temp[env.id] = env;
        });
        this.envListMap = temp;
      }
    },
    handleChangeDsType(type) {
      console.log(type);
      Vue.set(this.stepOneData, 'dataSourceType', type);
    },
    handleNext() {
      this.$refs.stepOneRef.validate((valid) => {
        if (this.clusterListMap[this.stepOneData.bindClusterId].workerCount && valid) {
          this.handleSubmitData(0, this.stepOneData);
        }
      });
    }
  },
  created() {
    this.stepOneData = { ...this.stepOneData, ...this.stepOneDataProps };
    this.listEnv();
    this.$store.dispatch(ACTIONS_TYPE.GET_DEPLOY_ENV_LIST);
    this.$store.dispatch(ACTIONS_TYPE.GET_CLUSTER_LIST, this.stepOneData.deployEnvType);
    this.$store.dispatch(ACTIONS_TYPE.GET_DS_TYPE_LIST, this.stepOneData.deployEnvType);
  },
  watch: {
    'stepOneData.deployEnvType': {
      handler(newValue, oldVue) {
        if (newValue !== oldVue) {
          this.$store.dispatch(ACTIONS_TYPE.GET_CLUSTER_LIST, newValue);
          this.$store.dispatch(ACTIONS_TYPE.GET_DS_TYPE_LIST, newValue);
          Vue.set(this.stepOneData, 'clusterEnvName', this.deployEnvListMap[newValue].name);
        }
      }
    }
  }
};
</script>

<style lang="less">
.step-one {
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-label {
    width: 85px;
  }
}
</style>
