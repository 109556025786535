<template>
  <div class="step step-four">
    <a-table :columns="datasourceTableColumns" :data-source="datasourceList" :rowKey="record=>record.instanceId"
             size="small">
      <template slot="name" slot-scope="record">
        {{ record.instanceDesc }}/{{ record.instanceId }}
      </template>
      <template slot="auth" slot-scope="record">
        <a-select :default-value="record.auth.type" size="small"
                  style="width: 160px;" @change="handleChangeAuthType(record, $event)">
          <a-select-option v-for="security in securityOptions" :key="security.securityType"
                           :rowKey="security.securityType">{{ security.secrityTypeI18nName || security.securityType }}
          </a-select-option>
        </a-select>
      </template>
      <template slot="account" slot-scope="record">
        <div class="account">
          <div v-if="record.auth.type===SECURITY_TYPE.USER_PASSWD || record.auth.type===SECURITY_TYPE.ONLY_PASSWD">
            <a-input v-if="record.auth.type!==SECURITY_TYPE.ONLY_PASSWD" v-model="record.auth.data.userName" placeholder="请输入数据库账号" size="small" style="width: 180px;" autocomplete="new-password"/>
            <cc-password-input v-model="record.auth.data.password" size="small" style="width: 180px;"/>
            <!--            <Input v-model="record.auth.data.password" placeholder="请输入数据库密码" style="width: 180px;"/>-->
            <a-checkbox :default-checked="record.auth.data.auto" @change="toggleAutoCreate(record, $event)"
                        v-if="securityOptionsObj[record.auth.type] && securityOptionsObj[record.auth.type].hasAutoCreateAccountOption">自动创建
            </a-checkbox>
          </div>
          <div v-else-if="record.auth.type===SECURITY_TYPE.USER_PASSWD_WITH_TLS">
            <a-input v-model="record.auth.data.username" placeholder="请输入数据库账号" style="width: 180px;"/>
            <a-input v-model="record.auth.data.password" placeholder="请输入数据库密码" style="width: 180px;"/>
            <label>
              {{ record.auth.data.tls ? record.auth.data.tls.name : '上传TLS文件' }}
              <input class="input-file" name="tls" type="file" @change="handleUpload(record, 'tls', $event)"/>
            </label>
          </div>
          <!--          <div v-else-if="record.auth.type==='阿里云AccessKey'">-->
          <!--            <Input v-model="record.auth.data.ak" placeholder="请输入AccessKey ID" style="width: 180px;"/>-->
          <!--            <Input v-model="record.auth.data.sk" placeholder="请输入AccessKey Secret" style="width: 180px;"/>-->
          <!--          </div>-->
          <div v-else-if="record.auth.type===SECURITY_TYPE.KERBEROS">
            <label for="kerberos">
              {{ record.auth.data.kerberos ? record.auth.data.kerberos.name : '上传KERBEROS配置文件' }}
              <input id="kerberos" class="input-file" name="kerberos" type="file"
                     @change="handleUpload(record, 'kerberos', $event)"/>
            </label>
            <label for="keybat">
              {{ record.auth.data.keybat ? record.auth.data.keybat.name : '上传Keybat文件' }}
              <input id="keybat" class="input-file" name="keybat" type="file"
                     @change="handleUpload(record, 'keybat', $event)"/>
            </label>
          </div>
        </div>
      </template>
    </a-table>
    <div class="footer">
      <a-button @click="handleBack">上一步</a-button>
      <!--      <a-button type="primary" @click="handleSubmit">下一步: 确认添加</a-button>-->
      <a-button type="primary" @click="handleSubmit">下一步：工单审批配置</a-button>
    </div>
  </div>
</template>

<script>
import { SECURITY_TYPE } from '@/consts';

export default {
  name: 'Four',
  props: {
    stepData: Array,
    handleBack: Function,
    handleSubmitData: Function
  },
  data() {
    return {
      SECURITY_TYPE,
      datasourceList: [],
      securityOptions: [],
      securityOptionsObj: {},
      selectOptions: [
      ],
      datasourceTableColumns: [
        {
          title: '实例名称/ID',
          scopedSlots: { customRender: 'name' }
        }, {
          title: '数据库认证方式',
          scopedSlots: { customRender: 'auth' },
          width: 300
        }, {
          title: '数据库账号',
          scopedSlots: { customRender: 'account' },
          width: 600
        }
      ]
    };
  },
  methods: {
    async getSecurityOption() {
      const {
        dataSourceType,
        deployEnvType
      } = this.stepData[0];
      const res = await this.$services.listDsSecurityOption({
        data: {
          dataSourceType,
          deployEnvType
        }
      });

      if (res.success) {
        const obj = {};
        this.securityOptions = [...res.data.securityOptions];
        res.data.securityOptions.forEach((security) => {
          obj[security.securityType] = security;
        });
        this.securityOptionsObj = obj;
        // eslint-disable-next-line prefer-destructuring
        const data = [];
        this.stepData[2].forEach((ds) => {
          ds.auth = {
            type: this.securityOptions.length > 0 ? this.securityOptions[0].securityType : '',
            data: {},
            auto: false
          };
          data.push(ds);
        });
        this.datasourceList = data;
      }
    },
    async checkAccount(ds) {
      const {
        deployEnvType,
        dataSourceType
      } = this.stepData[0];

      const {
        region,
        instanceId,
        auth
      } = ds;

      const {
        userName,
        password
      } = auth.data;

      const data = {
        dataSourceType,
        deployEnvType,
        instanceId,
        region,
        autoCreateAccount: auth.auto,
        dsPropsJson: JSON.stringify({
          userName,
          password
        })
      };

      const res = await this.$services.checkCloudDsAccount({ data });
      if (!res.success) {
        ds.accountError = true;
      }
      return res;
    },
    async handleSubmit() {
      const requestArr = [];
      this.datasourceList.forEach((ds) => {
        if (ds.auth.type === SECURITY_TYPE.USER_PASSWD) {
          ds.accountError = false;
          requestArr.push(this.checkAccount(ds));
        }
      });

      let canNext = true;
      const res = await Promise.all(requestArr);
      res.forEach((r) => {
        if (!r.success) {
          canNext = false;
        }
      });

      if (canNext) {
        this.handleSubmitData(3, this.datasourceList);
      }
    },
    handleUpload(record, type, e) {
      const { files } = e.target;
      this.datasourceList.forEach((ds) => {
        if (ds.instanceId === record.instanceId) {
          ds.auth.data[type] = files[0];
        }
      });

      this.datasourceList = [...this.datasourceList];
    },
    toggleAutoCreate(record, e) {
      const { datasourceList } = this;
      datasourceList.forEach((ds) => {
        if (ds.instanceId === record.instanceId) {
          ds.auth.auto = e.target.checked;
        }
      });
      this.datasourceList = [...datasourceList];
    },
    handleChangeAuthType(record, value) {
      const { datasourceList } = this;
      datasourceList.forEach((ds) => {
        if (ds.instanceId === record.instanceId) {
          ds.auth = {
            type: value,
            data: {
              userName: '',
              password: ''
            },
            auto: false
          };
        }
      });
      this.datasourceList = [...datasourceList];
    }
  },
  created() {
    this.getSecurityOption();
  }
};
</script>

<style lang="less" scoped>
.account {
  & > div {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 10px;
    }

    .label-button {
      height: 24px;
      line-height: 24px;
      border: 1px solid #d9d9d9;
    }
  }

  .input-file {
    visibility: hidden;
  }
}

</style>
