<template>
  <a-modal :visible="visible" title="工单审批流程" @cancel="handleCloseModal" :width="534" :mask-closable="false">
    <a-form-model :label-col="{span:3}" :wrapper-col="{span:16}">
      <!--      <a-form-model-item label="工单">-->
      <!--        <a-switch v-model="form.ticket" @change="handleTicketChange"/>-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="审批方式">
        <a-radio-group v-model="form.ticketType">
          <a-radio value="DINGDING">
            钉钉
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审批流程">
        <a-select v-model="form.ticketArr" placeholder="请选择审批流程" mode="multiple" :filter-option="filterOption"
                  show-search>
          <a-select-option v-for="template in ticketTemplates" :value="template.templateIdentity"
                           :key="template.templateIdentity">{{ template.approTemplateName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="footer">
      <a-button type="primary" @click="handleSubmit">保存</a-button>
      <a-button @click="handleCloseModal">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'TicketModal',
  props: {
    ds: Object,
    visible: Boolean,
    handleCloseModal: Function
  },
  data() {
    return {
      templates: [],
      templatesObj: {},
      form: {
        ticketType: 'DINGDING',
        ticketArr: []
      },
      ticketTemplates: [],
      ticketTemplatesObj: {}
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleSubmit() {
      const deleteFOs = [];
      const addFOs = [];
      this.form.ticketArr.forEach((t) => {
        if (!this.templatesObj[t]) {
          const add = {
            approvalType: this.form.ticketType,
            dataSourceId: this.ds.id,
            templateIdentity: this.ticketTemplatesObj[t].templateIdentity,
            templateName: this.ticketTemplatesObj[t].approTemplateName
          };
          addFOs.push(add);
          this.form.ticketArr.filter((t1) => t1 !== t);
        }
      });
      this.form.ticketArr.forEach((t) => {
        delete this.templatesObj[t];
      });

      Object.values(this.templatesObj)
        .forEach((t) => {
          deleteFOs.push({ dsApproTemplateId: t.id });
        });

      const res = await this.$services.modifyDsApproTemplates({
        data: {
          addFOs,
          deleteFOs
        },
        msg: '修改工单成功'
      });

      if (res.success) {
        this.handleCloseModal();
      }
    },
    async handleTicketChange(checked) {
      if (checked) {
        const res = await this.$services.listApproTemplates({ data: { approvalType: 'DINGDING' } });

        if (res.success) {
          this.ticketTemplates = res.data;
          const ticketTemplatesObj = {};
          res.data.forEach((ticket) => {
            ticketTemplatesObj[ticket.templateIdentity] = ticket;
          });
          this.ticketTemplatesObj = ticketTemplatesObj;
        }
      }
    },
    async getDsTemplates() {
      const res = await this.$services.listDsApproTemplateByDsId({ data: { dataSourceId: this.ds.id } });

      if (res.success) {
        const templatesObj = {};
        res.data.forEach((ticket) => {
          templatesObj[ticket.templateIdentity] = ticket;
        });
        this.templatesObj = templatesObj;
        this.templates = res.data;
        await this.handleTicketChange(true);
        const form = {};
        form.ticketType = res.data[0].approvalType;
        form.ticketArr = res.data.map((ticket) => ticket.templateIdentity);
        this.form = form;
      }
    }
  },
  created() {
    this.getDsTemplates();
  }
};
</script>

<style scoped>

</style>
