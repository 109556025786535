<template>
  <div class="add-datasource-steps">
    <a-breadcrumb style="margin-bottom: 20px;">
      <a-breadcrumb-item><a href="/#/system/datasource">数据源管理</a></a-breadcrumb-item>
      <a-breadcrumb-item>新增数据源</a-breadcrumb-item>
    </a-breadcrumb>
    <div v-if="step!==1" class="info">
      <section>
        <div>部署类型</div>
        <div>{{ stepData[0].clusterEnvName }}</div>
      </section>
      <section>
        <div>数据库类型</div>
        <div>{{ stepData[0].dataSourceType }}</div>
      </section>
      <section>
        <div>访问机器</div>
        <div>{{ stepData[0].bindClusterName || stepData[0].bindClusterId }}</div>
      </section>
      <section>
        <div>运行环境</div>
        <div>{{ stepData[0].envName }}</div>
      </section>
    </div>
    <steps v-if="stepData[0].deployEnvType === CLUSTER_ENV.ALIBABA_CLOUD_HOSTED && step > 1" :step="step"
           style="margin-top: 20px;"/>
    <!--    <steps :step="step"/>-->
    <step-one v-if="step===1" :handle-submit-data="handleSubmitData" :stepOneDataProps="stepData[0]"/>
    <div v-else-if="step===2" style="flex: 1">
      <step-two v-if="stepData[0].deployEnvType === CLUSTER_ENV.ALIBABA_CLOUD_HOSTED" :handle-back="handleBack"
                :handle-submit-data="handleSubmitData" :stepData="stepData"
                style="height: 100%;display: flex;flex-direction: column"/>
      <step-self v-else :handle-back="handleBack" :stepData="stepData"/>
    </div>
    <step-three v-else-if="step===3" :handle-back="handleBack" :handle-submit-data="handleSubmitData"
                :stepData="stepData"/>
    <step-four v-else-if="step===4" :handle-back="handleBack" :handle-submit-data="handleSubmitData"
               :stepData="stepData"/>
    <step-five v-else :handle-back="handleBack" :handle-submit-data="handleSubmitData"
               :stepData="stepData"/>
  </div>
</template>
<script>
import { CLUSTER_ENV } from '@/consts';
import Steps from '@/views/system/components/Steps.vue';
import One from './One.vue';
import Two from './Two.vue';
import Three from './Three.vue';
import Four from './Four.vue';
import Five from './Five.vue';
import Self from './Self.vue';

export default {
  name: 'Index',
  components: {
    Steps,
    StepOne: One,
    StepTwo: Two,
    StepThree: Three,
    StepFour: Four,
    StepFive: Five,
    StepSelf: Self
  },
  data() {
    return {
      CLUSTER_ENV,
      step: 1,
      stepData: [
        {}, {}, {}, {}
      ],
      newDatasource: {}
    };
  },
  methods: {
    handleSubmitData(step, data) {
      this.stepData[this.step - 1] = data;
      this.step++;
    },
    handleBack(index, data) {
      this.step--;
      this.stepData[index] = data;
    }
  }
};
</script>

<style lang="less" scoped>
.add-datasource-steps {
  height: 100%;
  display: flex;
  flex-direction: column;

  .info {
    width: 100%;
    display: flex;
    border-right: 1px solid #DADADA;

    section {
      display: flex;
      width: 25%;
      height: 32px;
      line-height: 32px;
      font-size: 12px;

      div:first-child {
        width: 100px;
        background: rgba(244, 244, 244, 1);
        text-align: right;
        padding-right: 10px;
        border-top: 1px solid #DADADA;
        border-bottom: 1px solid #DADADA;

        &:first-child {
          border-left: 1px solid #DADADA;
        }
      }

      div:last-child {
        flex: 1;
        //border: 1px solid rgba(244, 244, 244, 1);
        padding-left: 10px;
        border: 1px solid #DADADA;
        border-right: none;
      }
    }
  }
}
</style>
