<template>
  <div class="datasource">
    <query-header :handle-click-add-btn="handleClickAddBtn" :handle-query="handleQuery" :handle-refresh="getDsList"
                  :query="queryForm" :select-options="Object.values(deployEnvListMap)"
                  :show-add-btn="userInfo.authArr.includes('DS_ADD')"
                  add-btn-text="添加数据源" placeholder="请输入实例ID/名称查询"/>
    <a-table :columns="datasourceColumns" :data-source="datasourceList" :locale="{emptyText: '暂无数据'}"
             :rowKey="record => record.id"
             size="small">
      <template slot="instanceName" slot-scope="record">
        <cc-data-source-icon :instanceType="record.deployEnvType" :size="18" :type="record.dataSourceType"
                             color="#4DBAEE"></cc-data-source-icon>
        {{ record.instanceId }}/{{ record.instanceDesc }}
        <cc-iconfont v-if="record.consoleTaskState==='FAILED'" color="#FF6E0D"
                     name="job-error" @click.native="handleGoConsoleJob(record)"/>
        <cc-iconfont v-if="userInfo.authArr.includes('DS_UPDATE_DESC')" :size="8" name="edit"
                     style="margin-right: 4px;margin-left: 4px;"
                     @click.native="handleEditDatasourceDesc(record)"/>
        <a-popover placement="right" trigger="click">
          <cc-iconfont :size="12" name="detail" @click.native="getDsDetail(record)"/>
          <div slot="content" class="datasource-detail">
            <div class="info" style="display: flex;flex-direction: column;">
              <section>
                <div>
                  <span>host: </span>{{ selectedDatasource.defaultHost }}
                </div>
              </section>
              <section>
                <div><span>部署情况: </span>
                  {{
                    CLUSTER_TYPE[selectedDatasource.deployEnvType] && CLUSTER_TYPE[selectedDatasource.deployEnvType].name
                  }}
                </div>
                <div><span>名称: </span>{{ selectedDatasource.instanceDesc }}</div>
              </section>
              <section>
                <div><span>网络区域: </span>{{ regionList[selectedDatasource.region] }}</div>
                <div><span>版本号: </span>{{ selectedDatasource.version }}</div>
              </section>
              <section>
                <div><span>ID: </span>{{ selectedDatasource.instanceId }}</div>
                <div>
                  <span>类型: </span>
                  <cc-data-source-icon :instanceType="selectedDatasource.deployEnvType" :size="18"
                                       :type="selectedDatasource.dataSourceType"
                                       color="#4DBAEE"/>
                </div>
              </section>
              <section>
                <div><span>网络情况: </span>{{
                    selectedDatasource.defaultHost === selectedDatasource.privateHost ? '私有' : '公有'
                  }}
                </div>
                <div><span>添加时间: </span>{{ selectedDatasource.gmtCreate | formatTime('YYYY-MM-DD hh:mm:ss') }}</div>
              </section>
            </div>
            <div class="cluster">
              <section>
                <div><span>绑定的集群: </span>{{ selectedDatasource.bindClusterName }}
                </div>
              </section>
            </div>
            <div v-if="selectedDatasource.whitelist && selectedDatasource.whitelist.length" class="white">
              <div style="color: #888;font-weight: bold">白名单:</div>
              <div style="display: flex;flex-wrap: wrap;">
                <div v-for="white in selectedDatasource.whitelist" :key="white" style="width: 25%;margin-top: 5px;">
                  {{ white }}
                </div>
              </div>
            </div>
          </div>
        </a-popover>
      </template>
      <template slot="envType" slot-scope="record">
        {{ deployEnvListMap[record.deployEnvType] && deployEnvListMap[record.deployEnvType].name }}
      </template>
      <template slot="status" slot-scope="record">
        <cc-status :color="LIFECYCLE[record.lifeCycleState].color"
                   :content="LIFECYCLE[record.lifeCycleState].label"/>
      </template>
      <template slot="actions" slot-scope="record">
        <a-button
          size="small" type="link"
          @click.native="handleActions( {key:'ticket'}, record)">
          工单审批流程
        </a-button>
        <a-button
          v-if="record.deployEnvType === CLUSTER_ENV.ALIBABA_CLOUD_HOSTED && userInfo.authArr.includes('ALIYUN_RDS_ADD_WHITELIST')"
          :disabled="record.lifeCycleState !== 'CREATED'"
          size="small" type="link"
          @click.native="handleActions( {key:'add_whitelist'}, record)">
          添加白名单
        </a-button>
        <a-dropdown
          v-if="userInfo.authArr.includes('DS_UPDATE_CONFIG') || userInfo.authArr.includes('DS_UPDATE_ACCOUNT') || userInfo.authArr.includes('DS_DELETE')"
          :trigger="['click']">
          <a-button type="link">更多</a-button>
          <a-menu slot="overlay" @click="handleActions($event, record)">
            <a-menu-item v-if="userInfo.authArr.includes('DS_UPDATE_CONFIG')" key="modify_datasource"
                         :disabled="record.lifeCycleState !== 'CREATED'">
              编辑数据源
            </a-menu-item>
            <a-menu-item v-if="userInfo.authArr.includes('DS_UPDATE_HOST')"
                         :disabled="record.lifeCycleState !== 'CREATED'"
                         @click="handleActions({key: 'modify_address'}, record)">
              修改网络地址
            </a-menu-item>
            <a-menu-item v-if="userInfo.authArr.includes('DS_UPDATE_ACCOUNT')" key="modify_datasource_account"
                         :disabled="record.lifeCycleState !== 'CREATED'">
              修改数据库账号
            </a-menu-item>
            <a-menu-item v-if="userInfo.authArr.includes('DS_UPDATE_ACCOUNT')"
                         :disabled="record.lifeCycleState !== 'CREATED'"
                         @click="handleGoParams(record)"
            >
              修改参数配置
            </a-menu-item>
            <a-menu-item v-if="userInfo.authArr.includes('DS_DELETE')" key="delete_datasource">
              删除数据源
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
    <a-modal v-model="showModifyIpModal" :mask-closable=false :width="402" footer-hide title="修改网络地址">
      <div v-if="showModifyIpModal" class="edit-ip-modal">
        <div class="address">
          <div class="title">内网地址</div>
          <div class="ip">
            <a-input v-model="selectedDatasource.privateHost" placeholder="内网IP或域名" style="width:280px;"/>
          </div>
        </div>
        <div class="address">
          <div class="title">外网地址</div>
          <div class="ip">
            <a-input v-model="selectedDatasource.publicHost" placeholder="外网IP或域名" style="width:280px;"/>
          </div>
        </div>
        <div class="address">
          <div class="title">默认访问地址</div>
          <div class="ip">
            <a-radio-group v-model="selectedDatasource.defaultHostType">
              <a-radio value="private">
                内网
              </a-radio>
              <a-radio value="public">
                外网
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="footer">
          <a-button type="primary" @click="handleChangeHost">保存</a-button>
          <a-button @click="showModifyIpModal=false">取消</a-button>
        </div>
      </div>
    </a-modal>
    <modify-ds-account-modal :visible="showModifyDatasourceAccountModal"
                             :handle-close-modal="hideModifyDatasourceAccountModal" :ds="selectedDatasource" v-if="showModifyDatasourceAccountModal"/>
    <a-modal v-model="showModifyDatasourceModal" :closable="false" :mask-closable="false" :width="420" title="编辑数据源">
      <div v-if="showModifyDatasourceModal" class="edit-datasource-modal">
        <a-form-model :label-col="labelCol" :model="selectedDatasource" :wrapper-col="wrapperCol">
          <a-form-model-item label="部署类型">
            {{ CLUSTER_TYPE[selectedDatasource.deployEnvType].name }}
          </a-form-model-item>
          <a-form-model-item label="数据库类型">
            <cc-data-source-icon :instanceType="selectedDatasource.deployEnvType" :size="18"
                                 :type="selectedDatasource.dataSourceType"
                                 color="#4DBAEE"></cc-data-source-icon>
            {{ selectedDatasource.dataSourceType }}
          </a-form-model-item>
          <a-form-model-item label="绑定集群">
            <a-select v-model="selectedDatasource.bindClusterId" :dropdownMatchSelectWidth="false" placeholder="请选择"
                      style="width: 240px;">
              <a-select-option v-for="cluster in Object.values(clusterListMap)" :key="cluster.value"
                               :disabled="!cluster.runningCount" :value="cluster.value">
                {{ cluster.name }}/{{ cluster.desc }} 数量:{{ cluster.runningCount }}/{{ cluster.workerCount }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="运行环境">
            <a-select v-model="selectedDatasource.dsEnvId" placeholder="请选择" style="width: 240px;">
              <a-select-option v-for="env in Object.values(envListMap)" :key="env.id" :value="env.id">
                {{ env.envName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <!--        <step-one :handle-update-ds="handleUpdateDs" :stepOneDataProps="this.selectedDatasource" edit/>-->
        <div class="footer">
          <a-button type="primary" @click="updateDs">保存</a-button>
          <a-button @click="showModifyDatasourceModal=false">取消</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showEditDatasourceDesc" :closable="false" :width="402" cancelText="取消"
             okText="保存" wrapClassName="have-footer" @ok="handleUpdateDsDesc">
      <a-input v-model="selectedDatasource.instanceDesc" type="textarea"/>
    </a-modal>
    <a-modal v-model="showEditWhiteIpModal" title="添加白名单">
      <div style="margin-bottom: 10px;display: flex;align-items: center;">
        <div style="margin-right: 10px;">白名单类型</div>
        <a-radio-group v-model="selectedDatasource.whiteListAddType">
          <a-radio size="small" value="ADD_ALL">
            全部
          </a-radio>
          <a-radio size="small" value="PRIVATE_IP_ONLY">
            内网IP
          </a-radio>
          <a-radio size="small" value="PUBLIC_IP_ONLY">
            公网出口IP
          </a-radio>
        </a-radio-group>
      </div>

      <div class="worker-detail">
        <div class="circle">
          <cc-iconfont name="machine" size="16"/>
        </div>
        <div class="content">
          <div class="first">
            <div>{{ selectedCluster.clusterDesc }}</div>
            <div>{{ selectedCluster.clusterName }}</div>
          </div>
          <div class="second">
            <div>
              {{ CLUSTER_TYPE[selectedCluster.deployEnvType] && CLUSTER_TYPE[selectedCluster.deployEnvType].name }}
            </div>
            <div>
              <cc-iconfont :size="12" name="location" style="margin-right: 2px;"/>
              {{ regionList[selectedCluster.region] }}
            </div>
          </div>
        </div>
      </div>
      <a-table :columns="whitelistColumns" :data-source="selectedClusterWorkerList" :locale="{ emptyText: '暂无数据'}"
               :row-key="record => record.id" size="small">
        <template slot="name" slot-scope="record">
          {{ record.id }}/{{ record.workerName }}
        </template>
        <template slot="white" slot-scope="record">
          <div :style="isWhiteAdded(record) ? 'color: #52C41A;' : 'color: #999;'">
            {{ isWhiteAdded(record) ? '已添加' : '未添加' }}
          </div>
        </template>
      </a-table>
      <div class="footer">
        <a-button type="primary" @click="addClusterWhiteList">保存</a-button>
        <a-button @click="showEditWhiteIpModal=false">取消</a-button>
      </div>
    </a-modal>
    <a-modal v-model="showDeleteDatasourceModal" :width="400" title="删除">
      是否要删除名称为{{ selectedDatasource.instanceDesc }}的数据源？
      <div class="footer">
        <a-button type="primary" @click="handleDeleteDatasource">删除</a-button>
        <a-button @click="showDeleteDatasourceModal=false">取消</a-button>
      </div>
    </a-modal>
    <ticket-modal :visible="showTicketModal" :handle-close-modal="hideTicketModal" :ds="selectedDatasource"
                  v-if="showTicketModal"/>
  </div>
</template>

<script>
import { CLUSTER_ENV, CLUSTER_TYPE } from '@/consts';
import { mapState } from 'vuex';
import { ACTIONS_TYPE } from '@/store/actions';
import cloneDeep from 'lodash.clonedeep';
import datasourceMixin from '@/mixins/datasourceMixin';
import TicketModal from '@views/system/datasource/components/TicketModal.vue';
import ModifyDsAccountModal from '@views/system/datasource/components/ModifyDsAccountModal.vue';
import QueryHeader from '../components/QueryHeader.vue';

export default {
  name: 'DataSource',
  components: {
    ModifyDsAccountModal,
    TicketModal,
    QueryHeader
  },
  computed: {
    isWhiteAdded() {
      return (worker) => {
        let isAdded = false;
        switch (this.selectedDatasource.whiteListAddType) {
          case 'ADD_ALL':
            isAdded = this.selectedDsWhitelist.includes(worker.privateIp) && this.selectedDsWhitelist.includes(worker.publicIp);
            break;
          case 'PRIVATE_IP_ONLY':
            isAdded = this.selectedDsWhitelist.includes(worker.privateIp);
            break;
          case 'PUBLIC_IP_ONLY':
            isAdded = this.selectedDsWhitelist.includes(worker.publicIp);
            break;
          default:
            break;
        }

        return isAdded;
      };
    },
    ...mapState({
      deployEnvListMap: (state) => state.deployEnvListMap,
      clusterListMap: (state) => state.clusterListMap,
      userInfo: (state) => state.userInfo,
      regionList: (state) => state.regionList
    }),
    datasourceColumns() {
      const {
        deployEnvType,
        query
      } = this.datasourceFilterInfo;
      return [
        {
          title: '实例ID/名称',
          key: 'instanceName',
          filteredValue: [query] || null,
          onFilter: (value, record) => record.instanceId.includes(value) || record.instanceDesc.includes(value),
          scopedSlots: { customRender: 'instanceName' }
        }, {
          title: '类型',
          filteredValue: [deployEnvType] || null,
          key: 'envType',
          onFilter: (value, record) => value === 'all' || record.deployEnvType.includes(value),
          scopedSlots: { customRender: 'envType' }
        }, {
          dataIndex: 'dsEnvName',
          title: '运行环境',
          key: 'dsEnvName'
        }, {
          title: '状态',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        }, {
          title: '操作',
          // key: 'action',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'actions' }
        }];
    }
  },
  mixins: [datasourceMixin],
  data() {
    return {
      showTicketModal: false,
      selectedCluster: {},
      selectedClusterWorkerList: [],
      envListMap: {},
      CLUSTER_TYPE,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      LIFECYCLE: {
        CREATED: {
          label: '已完成',
          color: '#52C41A'
        },
        CREATING: {
          label: '创建中',
          color: '#FFA30E'
        },
        DELETING: {
          label: '删除中',
          color: '#FF6E0D'
        },
        DELETED: {
          label: '已删除',
          color: '#999'
        },
        LOCKED: {
          label: '已锁定',
          color: '#FF1815'
        }
      },
      queryForm: {
        type: 'all',
        text: ''
      },
      datasourceFilterInfo: {
        deployEnvType: '',
        query: ''
      },
      CLUSTER_ENV,
      selectedDatasource: {},
      showModifyIpModal: false,
      showModifyDatasourceModal: false,
      showEditDatasourceDesc: false,
      showModifyDatasourceAccountModal: false,
      showEditWhiteIpModal: false,
      showDeleteDatasourceModal: false,
      datasourceList: [],
      dataSourceTableMoreActions: [
        {
          name: '编辑数据源',
          value: 'modify_datasource'
        }, {
          name: '修改数据库账号',
          value: 'modify_datasource_account'
        },
        // {
        //   name: '删除数据库账号',
        //   value: 'delete_datasource_account'
        // },
        {
          name: '删除数据源',
          value: 'delete_datasource'
        }
      ],
      accountRules: {
        userName: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ]
      },
      whitelistColumns: [
        {
          title: '机器ID/名称',
          scopedSlots: { customRender: 'name' }
        }, {
          title: '白名单',
          scopedSlots: { customRender: 'white' }
        }
      ],
      selectedDsWhitelist: []
    };
  },
  methods: {
    async addClusterWhiteList() {
      const {
        id,
        whiteListAddType
      } = this.selectedDatasource;
      const data = {
        clusterIds: [this.selectedDatasource.bindClusterId],
        dataSourceId: id,
        whiteListAddType
      };

      const res = await this.$services.addClusterWhiteList({
        data,
        msg: '添加白名单成功'
      });
      if (res.success) {
        this.showEditWhiteIpModal = false;
      }
    },
    async getClusterDetail(clusterId) {
      const res = await this.$services.getCluster({ data: { clusterId } });
      if (res.success) {
        this.selectedCluster = res.data;
      }
    },
    async getWorkerList(clusterId) {
      const res = await this.$services.getWorkerList({ data: { clusterId } });

      if (res.success) {
        this.selectedClusterWorkerList = res.data;
      }
    },
    async queryWhiteList(dataSourceId) {
      const res = await this.$services.queryWhiteList({ data: { dataSourceId } });
      if (res.success) {
        this.selectedDsWhitelist = res.data;
      }
    },
    hideModifyDatasourceAccountModal() {
      this.showModifyDatasourceAccountModal = false;
    },
    async getDsDetail(ds) {
      const res = await this.getDs(ds.id);
      if (res.success) {
        const datasource = res.data;

        if (ds.deployEnvType === 'ALIBABA_CLOUD_HOSTED') {
          const res2 = await this.$services.queryWhiteList({ data: { dataSourceId: ds.id } });
          if (res2.success) {
            datasource.whitelist = res2.data;
          }
        }

        this.selectedDatasource = cloneDeep(datasource);
      }
    },
    async handleDeleteDatasource() {
      const res = await this.$services.deleteDs({
        data: { dataSourceId: this.selectedDatasource.id },
        msg: '删除数据源成功'
      });

      if (res.success) {
        await this.getDsList();
        this.showDeleteDatasourceModal = false;
      }
    },
    async updateDs() {
      const {
        id,
        bindClusterId,
        dsEnvId
      } = this.selectedDatasource;
      const data = {
        clusterId: bindClusterId,
        dataSourceId: id,
        envId: dsEnvId
      };

      const res = await this.$services.updateDsBindingAndEnv({
        data,
        msg: ''
      });

      if (res.success) {
        this.showModifyDatasourceModal = false;
        await this.getDsList();
      }
    },
    async listEnv() {
      const res = await this.$services.listDsEnv({ data: { envName: '' } });
      if (res.success) {
        const temp = {};
        res.data.forEach((env) => {
          temp[env.id] = env;
        });
        this.envListMap = temp;
      }
    },
    handleEditDatasourceDesc(ds) {
      this.selectedDatasource = cloneDeep(ds);
      this.showEditDatasourceDesc = true;
    },
    async handleUpdateDsDesc() {
      const {
        id,
        instanceDesc
      } = this.selectedDatasource;
      const res = await this.$services.updateDsDesc({
        data: {
          dataSourceId: id,
          desc: instanceDesc
        },
        msg: '更新数据库名称成功'
      });

      if (res.success) {
        this.showEditDatasourceDesc = false;
        this.getDsList();
      }
    },
    hideTicketModal() {
      this.showTicketModal = false;
    },
    async handleUpdateDs() {
      // const {} = ds;
    },
    _testConnection() {
      this.$refs.accountForm.validate(async (valid) => {
        if (valid) {
          const {
            bindClusterId,
            dataSourceType,
            userName,
            password,
            region,
            privateHost,
            publicHost,
            defaultHost,
            deployEnvType
          } = this.selectedDatasource;
          const data = {
            bindClusterId,
            dataSourceType,
            region,
            privateHost,
            publicHost,
            defaultHost,
            deployEnvType,
            dsPropsJson: JSON.stringify({
              userName,
              password
            }),
            securityType: 'USER_PASSWD'
          };
          this.testConnection(data);
        } else {
          return false;
        }
      });
    },
    async handleUpdateDsAccount() {
      const {
        id,
        userName,
        password
      } = this.selectedDatasource;
      const data = {
        dataSourceId: id,
        dsPropsJson: JSON.stringify({
          userName,
          password
        })
      };

      const res = await this.$services.updateDsAccount({
        data,
        msg: '更新账号成功'
      });

      if (res.success) {
        this.showModifyDatasourceAccountModal = false;
        await this.getDsList();
        this.hideAccountModal();
      }
    },
    async handleChangeHost() {
      const {
        id,
        defaultHostType,
        privateHost,
        publicHost
      } = this.selectedDatasource;
      const res = await this.$services.updateDsHosts({
        data: {
          dataSourceId: id,
          defaultHost: defaultHostType === 'private' ? privateHost : publicHost,
          publicHost,
          privateHost
        }
      });

      if (res.success) {
        this.$Message.success('修改地址成功');
        this.showModifyIpModal = false;
      }
    },
    async getDs(id) {
      const res = await this.$services.getDs({ data: { dataSourceId: id } });
      if (res.success) {
        return res;
      }
    },
    async getDsList() {
      const res = await this.$services.getDsList({ data: { useVisibility: true } });

      if (res.success) {
        this.datasourceList = res.data;
      }
    },
    handleQuery() {
      const {
        type,
        text
      } = this.queryForm;
      this.datasourceFilterInfo = {
        deployEnvType: type,
        query: text
      };
    },
    handleClickAddBtn() {
      this.$router.push({ name: 'System_DataSource_Add' });
    },
    handleGoParams(row) {
      this.$router.push({ path: `datasource/params/${row.id}` });
    },
    async handleActions(action, row) {
      const res = await this.getDs(row.id);
      if (res.success) {
        if (action.key === 'add_whitelist') {
          res.data.whiteListAddType = 'ADD_ALL';
        }
        this.selectedDatasource = res.data;
      }
      switch (action.key) {
        case 'ticket':
          this.showTicketModal = true;
          break;
        case 'modify_datasource':
          await this.listEnv();
          await this.$store.dispatch(ACTIONS_TYPE.GET_CLUSTER_LIST, this.selectedDatasource.deployEnvType);

          this.showModifyDatasourceModal = true;
          break;
        case 'add_whitelist':
          await this.queryWhiteList(row.id);
          await this.getWorkerList(row.bindClusterId);
          await this.getClusterDetail(row.bindClusterId);
          this.showEditWhiteIpModal = true;
          break;
        case 'modify_address':
          this.selectedDatasource.defaultHostType = this.selectedDatasource.defaultHost === this.selectedDatasource.privateHost ? 'private' : 'public';
          this.selectedDatasource = { ...this.selectedDatasource };
          this.showModifyIpModal = true;
          break;
        case 'modify_datasource_account':
          this.showModifyDatasourceAccountModal = true;
          break;
        case 'delete_datasource':
          this.showDeleteDatasourceModal = true;
          break;
        default:
          break;
      }
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/system/console_job/${row.consoleJobId}` });
    }
  },
  created() {
    this.getDsList();
    this.$store.dispatch(ACTIONS_TYPE.GET_DEPLOY_ENV_LIST);
  }
};
</script>

<style lang="less" scoped>
.datasource-detail {
  min-width: 480px;
  max-width: 480px;

  & > div {
    padding: 5px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #DADADA;
    }
  }

  section {
    display: flex;
    padding: 5px 0;
    font-weight: bold;

    &:not(:first-child) > div {
      width: 50%;
    }

    span {
      color: #888;
    }
  }
}

.edit-ip-modal {
  font-size: 12px;

  .address {
    display: flex;
    align-items: center;
    //justify-content: space-between;

    .title {
      min-width: 80px;
    }

    &:not(first-child) {
      margin-bottom: 12px;
    }
  }
}

.worker-detail {
  display: flex;
  align-items: center;
  height: 70px;
  border: 1px solid rgba(234, 234, 234, 1);
  border-bottom: none;
  background: rgba(250, 250, 250, 1);

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 193, 92, 1);
    box-shadow: 0 3px 12px 0 rgba(255, 192, 92, 1);
    text-align: center;
    line-height: 40px;
    margin-left: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 17px;

    & > div {
      display: flex;
    }

    .first {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      & > div:first-child {
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
      }
    }

    .second {
      & > div {
        border-radius: 14px;
        padding: 0 5px;
        background-color: rgba(224, 224, 224, 1);

        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}

</style>
